function AINative() {
  return (
    <div className="features">
      <section id="section5">
        <div className="flex justify-center items-center w-full">
          <div className="main-title">
            The AI assistant capable of being omnipresent at all times.
          </div>
        </div>
        <div className="content">
            <div className="description">
              Sitegenie redefines construction project management by integrating voice recognition and AI, providing professional and efficient solutions for modern construction teams. We can transform the industry by reducing delays and improving overall productivity.
            </div>
            <div className="img">
              <img src="./img/features/item1.svg" className="h-full" alt="item1" />
            </div>
          </div>
      </section>
      <section id="section6">
        <div className="frame6">
          <div className="content">
            <div className="title">Digitize</div>
            <div className="text">Voice recognition and let Sitegenie Bot understand and execute them. From updating project timelines to assigning tasks! Sitegenie harnesses the power of AI to analyze voice data and extra insights. The app can identify patterns, assess project risks and provide predictive analytics.</div>
          </div>
          <div className="number1">1</div>
        </div>
        <div className="frame7">
          <div className="number2">2</div>
          <div className="content">
            <div className="title">Synchronize</div>
            <div className="text">Sitegenie harnesses the power of AI to analyze voice data and extra insights. The app can identify patterns, assess project risks and provide predictive analytics.</div>
          </div>
        </div>
        <div className="frame8">
          <div className="content">
            <div className="title">Cutting Edge AI</div>
            <div className="text">Seamless scheduling and management of construction meetings is simplified with efficient tools. Streamlining the process with AI allows for easy coordination, enhancing overall project communication and ensuring effective collaboration during construction meetings.</div>
          </div>
          <div className="number3">3</div>
        </div>
        <div className="frame9">
          <div className="number4">4</div>
          <div className="content">
            <div className="title">Real time updates</div>
            <div className="text">Real-time updates are crucial for efficient project planning and safety. Instant access to current information enables swift decision-making, optimizing project efficiency and proactively addressing safety risks for a secure working environment.</div>
          </div>
        </div>
        <div className="tablet">
          <div className="bottom-title">
            Our app also features real-time collaboration and smart planning assistance.
          </div>
        </div>
        <div className="mobile">
          <div className="bottom-title">
            Our app also features real-time collaboration and smart planning assistance.
          </div>
        </div>
      </section>
    </div>
  );
}

export default AINative;