import React, { ChangeEvent } from 'react';
import { useState, useEffect } from "react";
import axios from 'axios';
import {SITEGENIE_CREATEDB_URL, SITEGENIE_REGISTER_COMPANY_URL} from '../helper'

function Waiting() {
  interface FormData {
    [key: string]: string;
  }

  const [formData, setFormData] = useState<FormData>({
    companyName: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: ''
  });
  
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const isFormValid = () => {
    return formData.companyName && formData.firstName && formData.lastName && formData.email && formData.phone;
  };

  const submitToCreateDBAPI = () => {
    const data = {
        "new_db_name": `${formData.companyName.toLowerCase().replace(/\s+/g, '')}DB`,
    };

    axios.post(SITEGENIE_CREATEDB_URL, data)
        .then(response => {
            console.log(response.data);
            alert(response.data.message);
        })
        .catch(error => {
            console.error('There was an error for creating a schema!', error);
        });
  };

  const submitToRegisterAPI = () => {
    const databaseName = formData.companyName.toLowerCase().replace(/\s+/g, '') + 'DB';
    const firstName = formData.firstName.toLowerCase();
    const lastName = formData.lastName.toLowerCase();
    const password = firstName + lastName;

    const data = {
        "CompanyName": formData.companyName,
        "DatabaseName": databaseName,
        "Users": [{
          "email": formData.email,
          "first_Name": firstName,
          "last_Name": lastName,
          "phone_Number": formData.phone,
          "password": password
          //"password": `${formData.lastName.charAt(0).toUpperCase()}${formData.phone.slice(-4)}`
        }]
    };

    axios.post(SITEGENIE_REGISTER_COMPANY_URL, data)
        .then(response => {
            console.log(response.data);
            alert(response.data.message);
        })
        .catch(error => {
            console.error('There was an error for registration!', error);
        });
  };

  const handleSubmit = (e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isFormValid()) {
        submitToCreateDBAPI(); 
        alert("Thank you for joining our waiting list!");
        submitToRegisterAPI();
        alert("Thank you for registration!");
    } else {
        alert("Please fill in all the fields.");
    }
  };

  //Return to the top of Page
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return <div className="waiting">
    <section id="section10">
      <div className="title">
        AI Native technology 
        <div className="text-white">to assist the SME construction industry</div>
      </div>
      <div className="description">This limited release is exclusively available to selected experienced professionals in the construction industry. To be among the first adopters, please contact us using the information below:</div>
    </section>
    <section id="section12">
      <form onSubmit={handleSubmit}>
      <div className="flex mt-[25px]">
          <div className="w-full">
          <div className="label">Company Name</div>
            <div className="inputbox">
              <input type="text" name="companyName" onChange={handleChange} value={formData.companyName}/>
            </div>
          </div>
        </div>
        <div className="flex mt-[25px]">
          <div className="w-1/2 pr-[10px]">
            <div className="label">First name</div>
            <div className="inputbox">
              <input type="text" name="firstName" onChange={handleChange} value={formData.firstName}/>
            </div>
          </div>
          <div className="w-1/2 pl-[10px]">
            <div className="label">Surname</div>
            <div className="inputbox">
              <input type="text" name="lastName" onChange={handleChange} value={formData.lastName}/>
            </div>
          </div>
        </div>
        <div className="flex mt-[25px]">
          <div className="w-full">
          <div className="label">Email</div>
            <div className="inputbox">
              <input type="email" name="email" onChange={handleChange} value={formData.email}/>
            </div>
          </div>
        </div>
        <div className="flex mt-[25px]">
          <div className="w-full">
          <div className="label">Phone number</div>
            <div className="inputbox">
              <input type="tel" name="phone" onChange={handleChange} value={formData.phone}/>
            </div>
          </div>
        </div>
        <div className="flex justify-center mt-[25px]">
          <div className="button">
            <div className="button-text">
              <button type="submit" disabled={!isFormValid()}>Submit</button>
            </div>
          </div>
        </div>
      </form>
    </section>
  </div>;
}

export default Waiting;
