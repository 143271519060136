function Team() {
  return (
    <div className="aboutus">
      <section id="section11">
        <div className="desktop">
          <div className="title">ABOUT OUR TEAM</div>
          <div className="description">Sitegenie, your wish is my command.</div>
        </div>
        <div className="title1">WHO WE ARE</div>
        <div className="frame11">
          <div className="mobile text">
            We are an innovative company that specializes in AI Technology to
            help facilitate the construction process by integrating advanced
            technology.
          </div>
          <div className="image">
            <img
              src="./img/aboutus/world.svg"
              className="w-full h-full"
              alt="world"
            />
          </div>
          <div className="text">
            <p className="desktop">
              We are an innovative company that specializes in AI Technology to
              help facilitate the construction process by integrating advanced
              technology.
            </p>
            <p className="tablet">
              We are an innovative company that specializes in AI Technology to
              help facilitate the construction process by integrating advanced
              technology.
            </p>
            <br />
            <p>
              Established in 2023 and headquartered in Toronto, we have performed
              extensive research and understanding of the construction industry to
              curate a unique and simple application for the construction
              industry.
            </p>
          </div>
        </div>
        <div className="tablet">
          <div className="title">ABOUT OUR TEAM</div>
          <div className="description">Sitegenie, your wish is my command.</div>
          <div className="description1">
            We are an innovative company that specializes in AI Technology to help facilitate the construction process by integrating advanced technology. Established in 2023 and headquartered in Toronto, we have performed extensive research and understanding of the construction industry to curate a unique and simple application for the construction industry.
          </div>
        </div>

        <div className="title2">FOUNDERS</div>
        <div className="frame12">
          <div className="profile-card">
            <div className="profile-img">
              <img src="./img/aboutus/profile1.png" alt="Catherine_photo" />
            </div>
            <div className="profile-name">Catherine Li</div>
            <div className="profile-role">Co-Founder</div>
            <div className="profile-description">
            Catherine Li is a distinguished graduate of the Engineering Science program at the University of Toronto. During her graduate studies in Electrical Engineering, she developed a keen interest in land development, leading her to co-found QH Design Build, a Tarion Registered Corporation renowned for its extensive experience in construction and development projects. Catherine has held key positions in various influential companies and is a staunch advocate for continuing education and lifelong learning, holding numerous industry qualifications. In early 2023, Catherine founded SiteGenie AI Engineering and Simulator Gravity, focusing on creating AI applications for SME industries.
            </div>
            {/*
            <div className="profile-description">CEO, QH Design Build.</div>
            <div className="profile-description">CEO, Keyglobe Land Developments.</div>
            <div className="profile-description">Co-Owner, Battaglia Architect Inc.</div>
            <div className="profile-description">Co-Owner, MAS Interior Design. Inc.</div>
            <div className="profile-description">Engineer. BCIN (Building Code)</div>
            */}
            <div className="profile-links">
              {/*
              <div className="profile-link">
                <img src="./img/aboutus/link1.svg" className="w-full h-full" alt="link1" />
              </div>
              */}
              <div className="profile-link">
                <a href="https://www.linkedin.com/in/catherinelisitegenie/"><img src="./img/aboutus/linkedin_icon.png" className="w-full h-full" alt="link2" /></a>
              </div>
              {/*
              <div className="profile-link">
                <img src="./img/aboutus/link3.svg" className="w-full h-full" alt="link3" />
              </div>
              */}
            </div>
          </div>
          <div className="profile-card">
            <div className="profile-img">
              <img src="./img/aboutus/profile2.png" alt="Sally_photo" />
            </div>
            <div className="profile-name">Sally Dong</div>
            <div className="profile-role">Co-Founder</div>
            <div className="profile-description">Capital Accounting - CA Natural Gas Financial Service</div>
            <div className="profile-description">Chartered Financial Analyst</div>
            <div className="profile-description">Certified General Accountant</div>
            <div className="profile-description">Master of Business Administration</div>
            <div className="profile-description">&nbsp;</div>
            <div className="profile-links">
              {/*
              <div className="profile-link">
                <img src="./img/aboutus/link1.svg" className="w-full h-full" alt="link1" />
              </div>
              */}
              <div className="profile-link">
                <a href="https://www.linkedin.com/in/sallydong/"><img src="./img/aboutus/linkedin_icon.png" className="w-full h-full" alt="link2" /></a>
              </div>
              {/*
              <div className="profile-link">
                <img src="./img/aboutus/link3.svg" className="w-full h-full" alt="link3" />
              </div>
              */}
            </div>
          </div>
          <div className="profile-card">
            <div className="profile-img">
              <img src="./img/aboutus/LiqunFu.jpg" alt="LiqunFu_photo" />
            </div>
            <div className="profile-name">Liqun Fu</div>
            <div className="profile-role">Co-Founder</div>
            <div className="profile-description">Full Professor, School of Information Science and Engineering, Xiamen University.</div>
            <div className="profile-description">Visiting Professor, School of Engineering, Stanford University</div>
            <div className="profile-description">IEEE Senior Member</div>
            <div className="profile-description">&nbsp;</div>
            <div className="profile-links">
              {/*
              <div className="profile-link">
                <img src="./img/aboutus/link1.svg" className="w-full h-full" alt="link1" />
              </div>
              */}
              <div className="profile-link">
              <a href="https://www.linkedin.com/in/liqun-fu-59152513/"><img src="./img/aboutus/linkedin_icon.png" className="w-full h-full" alt="link2" /></a>
              </div>
              {/*
              <div className="profile-link">
                <img src="./img/aboutus/link3.svg" className="w-full h-full" alt="link3" />
              </div>
              */}
            </div>
          </div>
        </div>
        <div className="title3 desktop">TECHNICAL TEAM</div>
        <div className="title3 tablet">TECHNICAL TEAM</div>
        <div className="title3 mobile">TECHNICAL TEAM</div>
        <div className="frame13">
          <div className="profile-card">
            <div className="profile-img">
              <img src="./img/aboutus/Rohan-Linkdin.jpg" alt="Rohan_photo" />
            </div>
            <div className="profile-name">Rohan Jhonson</div>
            <div className="profile-role">Team Leader, ML Ops Engineer</div>
            <div className="profile-links">
              <div className="profile-link">
                <a href="https://www.linkedin.com/in/rohan-jhonson/"><img src="./img/aboutus/linkedin_icon.png" className="w-full h-full" alt="link2" /></a>
              </div>
            </div>
          </div>
          <div className="profile-card">
            <div className="profile-img">
              <img src="./img/aboutus/Judy.JPG" alt="Judy_photo" />
            </div>
            <div className="profile-name">Judy Ng</div>
            <div className="profile-role">Chief Technical Advisor</div>
            <div className="profile-links">
              <div className="profile-link">
                <a href="https://www.linkedin.com/in/judy-carmen-ng-2742683/"><img src="./img/aboutus/linkedin_icon.png" className="w-full h-full" alt="link2" /></a>
              </div>
            </div>
          </div>
          <div className="profile-card">
            <div className="profile-img">
              <img src="./img/aboutus/Raphael-Zhou.png" alt="Raphael_photo" />
            </div>
            <div className="profile-name">Raphael Zhou</div>
            <div className="profile-role">Full Stack Developer</div>
            <div className="profile-links">
              <div className="profile-link">
                <a href="https://www.linkedin.com/in/raphael-zhou/"><img src="./img/aboutus/linkedin_icon.png" className="w-full h-full" alt="link2" /></a>
              </div>
            </div>
          </div>
          <div className="profile-card">
            <div className="profile-img">
              <img src="./img/aboutus/Henry.jpg" alt="Henry_photo" />
            </div>
            <div className="profile-name">Henry Xue</div>
            <div className="profile-role">
              Data Scientist
              
            </div>
            <div className="profile-links">
              <div className="profile-link">
                <a href="https://www.linkedin.com/in/henry-xue-sitegenie/"><img src="./img/aboutus/linkedin_icon.png" className="w-full h-full" alt="link2" /></a>
              </div>
            </div>
          </div>
          <div className="profile-card">
            <div className="profile-img">
              <img src="./img/aboutus/Oliver.png" alt="Oliver_photo" />
            </div>
            <div className="profile-name">Oliver Zeng</div>
            <div className="profile-role">
              Technical Business Analyst
              <br />
              School of Engineering,
              <br />
              Purdue University
            </div>
            <div className="profile-links">
              <div className="profile-link">
                <a href="#"><img src="./img/aboutus/linkedin_icon.png" className="w-full h-full" alt="link2" /></a>
              </div>
            </div>
          </div>
          <div className="profile-card">
            <div className="profile-img">
              <img src="./img/aboutus/Andy.jpg" alt="Andy_photo" />
            </div>
            <div className="profile-name">Andy Xi</div>
            <div className="profile-role">
              Quality Assurance Specialist
              <br />
              Computer Engineering,
              <br />
              University of Toronto
            </div>
            <div className="profile-links">
              <div className="profile-link">
                <a href="https://www.linkedin.com/in/andy-xi/"><img src="./img/aboutus/linkedin_icon.png" className="w-full h-full" alt="link2" /></a>
              </div>
            </div>
          </div>
          <div className="profile-card">
            <div className="profile-img">
              <img src="./img/aboutus/Zackary.png" alt="Zackary_photo" />
            </div>
            <div className="profile-name">Zackary Xie</div>
            <div className="profile-role">AI Product Specialist</div>
            <div className="profile-links">
              <div className="profile-link">
                <a href="#"><img src="./img/aboutus/linkedin_icon.png" className="w-full h-full" alt="link2" /></a>
              </div>
            </div>
          </div>
          <div className="profile-card">
            <div className="profile-img">
              <img src="./img/aboutus/JessieTang_600.jpg" alt="JessieTang_photo" />
            </div>
            <div className="profile-name">Vivian Tang</div>
            <div className="profile-role">Art Director</div>
            <div className="profile-links">
              <div className="profile-link">
                <a href="https://www.linkedin.com/in/viart/"><img src="./img/aboutus/linkedin_icon.png" className="w-full h-full" alt="link2" /></a>
              </div>
            </div>
          </div>
          <div className="profile-card lg:col-start-2">
            <div className="profile-img">
              <img src="./img/aboutus/Jess-Ai-Profile.png" alt="Jessie_photo" />
            </div>
            <div className="profile-name">Jessie Zheng</div>
            <div className="profile-role">UX/UI Designer Illustrator</div>
            <div className="profile-links">
              <div className="profile-link">
                <a href="https://www.linkedin.com/in/jessie-zheng-yt/"><img src="./img/aboutus/linkedin_icon.png" className="w-full h-full" alt="link2" /></a>
              </div>
            </div>
          </div>
          <div className="profile-card">
            <div className="profile-img">
              <img src="./img/aboutus/David.jpg" alt="David_photo" />
            </div>
            <div className="profile-name">David Clement</div>
            <div className="profile-role">Videographer</div>
            <div className="profile-links">
              <div className="profile-link">
                <a href="https://www.linkedin.com/in/david-clement-ai/"><img src="./img/aboutus/linkedin_icon.png" className="w-full h-full" alt="link2" /></a>
              </div>
            </div>
          </div>
        </div>
        <div className="title4">INDUSTRY VERIFICATION TEAM</div>
        <div className="frame14">
          <div className="profile-card">
            <div className="profile-img">
              <img src="./img/aboutus/WilliamWong.jpeg" alt="William_photo" />
            </div>
            <div className="profile-name">William Wong</div>
            <div className="profile-role">
              Senior Engineer
              <br />
              Professional Engineer Ontario (P.Eng), 
              <br />
              Ontario Building Officals Association (OBOA), 
              <br />
              Chief Building Official
            </div>
          </div>
          <div className="profile-card">
            <div className="profile-img">
              <img src="./img/aboutus/Joe-QH.png" alt="Joe_photo" />
            </div>
            <div className="profile-name">Joseph Battaglia</div>
            <div className="profile-role">Principal Architect
              <br />
              Ontario Association Of Architects (OAA)
            </div>
          </div>
          <div className="profile-card">
            <div className="profile-img">
              <img src="./img/aboutus/Helya-Ai-Profile.png" alt="Helya_photo" />
            </div>
            <div className="profile-name">Helya Mozafari</div>
            <div className="profile-role">Urban Planner</div>
          </div>
          <div className="profile-card">
            <div className="profile-img">
              <img src="./img/aboutus/Magid.jpg" alt="Magid_photo" />
            </div>
            <div className="profile-name">Magid Youssef</div>
            <div className="profile-role">
              Senior Architect
              <br />
              Leadership in Energy and Environmental Design (LEED), 
              <br />
              Ontario Association Of Architects (OAA)
            </div>
          </div>
          <div className="profile-card">
          <div className="profile-img">
              <img src="./img/aboutus/Ury-QH-Group.png" alt="Ury_photo" />
            </div>
            <div className="profile-name">Ury Fuks</div>
            <div className="profile-role">
              Civil Engineer, Architect Drafter
            </div>
          </div>
          <div className="profile-card">
            <div className="profile-img">
              <img src="./img/aboutus/Leandro_Martin.png" alt="Leandro_photo" />
            </div>
            <div className="profile-name">Leandro Martin</div>
            <div className="profile-role">
              Zoning Review Specialist
            </div>
          </div>
          <div className="profile-card">
            <div className="profile-img">
              <img src="./img/aboutus/Kate_Gallofin.jpg" alt="Kate_photo" />
            </div>
            <div className="profile-name">Kate Gallofin</div>
            <div className="profile-role">
              CAD-Revit Modeler
            </div>
          </div>
          <div className="profile-card">
            <div className="profile-img">
              <img src="./img/aboutus/Davoud_Motahar.png" alt="Davoud_photo" />
            </div>
            <div className="profile-name">Davoud Motahar</div>
            <div className="profile-role">
              Building Information Modeling (BIM) Designer
            </div>
          </div>
          <div className="profile-card lg:col-start-2">
            <div className="profile-img">
              <img src="./img/aboutus/Nasim_Taebi.JPG" alt="Nasim_photo" />
            </div>
            <div className="profile-name">Nasim Taebi</div>
            <div className="profile-role">
              Architectural Drafter
            </div>
          </div>
          <div className="profile-card">
            <div className="profile-img">
              <img src="./img/aboutus/Hamid_Verki.png" alt="Hamid_photo" />
            </div>
            <div className="profile-name">Hamid Verki</div>
            <div className="profile-role">
              Architectural Designer
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Team;
