export const SITEGENIE_LOGIN_URL = "https://demo.sitegenie.ai"
export const SITEGENIE_WAITLIST_URL ="https://api.sitegenie.ai/api/UsersControllerv1/AddToWaitlist"
export const SITEGENIE_CREATEDB_URL = "https://createdb.sitegenie.ai/replicate_schema"
export const SITEGENIE_REGISTER_COMPANY_URL = "https://createdb.sitegenie.ai/register_company"

export const LOGOS1 = [
    "./img/industry/OAA_1.png",
    "./img/industry/peo-logo_360.png",
    "./img/industry/BILD.png",
    "./img/industry/OBOA.png",
    "./img/industry/qh-design-build_480.png",
    "./img/industry/mas.png",
    "./img/industry/keyglobe_logo.png",
    "./img/industry/battaglia_architect_logo.png",
]

export const LOGOS2 = [
    "./img/industry/OADC_logo.png",
    "./img/industry/CIPH_logo.png",
    "./img/industry/RFCA_logo.png",
    "./img/industry/ISCA_logo.jpg",
    "./img/industry/CSME_logo.png",
    "./img/industry/CSEA_logo.png",
    "./img/industry/CIP_ICU_logo.png",
    "./img/industry/FenestrationCanada_logo.png",
    "./img/industry/OGCA_logo.jpg",
]

export const LOGOS3 = [
    "./img/industry/OIRCA_logo.png",
    "./img/industry/ARIDO_logo.jpg",
    "./img/industry/OSSFA_logo.png",
    "./img/industry/ORAC_logo.jpg",
    "./img/industry/HRAI_logo.png",
    "./img/industry/ECAO_logo.png",
    "./img/industry/OSPE_logo.jpg",
    "./img/industry/COCA_logo.jpg",
    "./img/industry/LBMAO_logo.png",
]
/*
export const LOGOS2 = [
    "./img/industry/xu_structural.png",
    "./img/industry/al_miley_logo.png",
    "./img/industry/nistek_services_logo.png",
    "./img/industry/sincerity_roofing_logo.png",
    "./img/industry/j_k_cabinetry_logo.png",
    "./img/industry/wallace_excavation__demolition_logo.jpg",
    "./img/industry/mandarin_surveyors_ltd_logo.png",
    "./img/industry/gta_custom_concrete_logo.png",
]

export const LOGOS3 = [
    "./img/industry/grandeur_flooring_logo.png",
    "./img/industry/aaa_plumbing_logo.png",
    "./img/industry/geebo_electric_logo.png",
    "./img/industry/b_b_logo.png",
    "./img/industry/craft_door_logo.png",
    "./img/industry/Northstar-logo.png",
    "./img/industry/copperleaf_windows_logo.png",
    "./img/industry/CaliGreen-logo.png",
]
*/

export const LOGOS4 = [
    "./img/industry/Milan-Logo.png",
    "./img/industry/valueone_rugs_logo.png",
    "./img/industry/andar_logo.png",
    "./img/industry/maple_overhead_logo.jpg",
    "./img/industry/eco-plumber-logo.png",
    "./img/industry/hanson-logo.jpeg",
    "./img/industry/ccrelectricsupply_logo.png",
    "./img/industry/mrhome_garage_door_logo.jpg",
]

export const LOGOS5 = [
    "./img/industry/hanson_logo.png",
    "./img/industry/shinetech_logo.png",
    "./img/industry/kingwave-logo.png",
    "./img/industry/elmid_design_logo.jpg",
    "./img/industry/xgarage_door_logo.jpg",
    "./img/industry/nextwood_outdoor_logo.png",
]