// components/NewsPage.tsx
import React from 'react';
import NewsItem from '../components/NewsItem';
import NewsSideBar from '../components/NewsSideBar';
import { NewsItem as NewsItemType } from '../interfaces/NewsItem';

interface NewsPageProps {
  newsData: NewsItemType[];
}

const NewsPage: React.FC<NewsPageProps> = ({ newsData }) => {
  return (
    <section id="sectionNews">
      <div className="flex justify-center items-center w-full">
          <div className="main-title">
            LATEST NEWS
          </div>
      </div>
      <div className="news-main desktop">
        <div className="news-page">
          {newsData.map(newsItem => (
            <NewsItem key={newsItem.id} newsItem={newsItem} />
          ))}
        </div>
        <div className="news-sidebar">
          <h2>Popular News</h2>
          {newsData.slice(0,4).map(newsItem => (
            <NewsSideBar key={newsItem.id} newsItem={newsItem} />
          ))}
        </div>
      </div>
      <div className="news-page tablet mobile">
          {newsData.map(newsItem => (
            <NewsItem key={newsItem.id} newsItem={newsItem} />
          ))}
        </div>
    </section>
  );
};

export default NewsPage;
