import { Routes, Route } from 'react-router-dom';
import Home from 'pages/Home';
import AINative from 'pages/AINative';
import Team from 'pages/Team';
import Contactus from 'pages/Contactus';
import IndustryUsers from 'pages/IndustryUsers';
import Waiting from 'pages/Waiting';
import Header from 'components/Header';
import Footer from 'components/Footer';
import NewsPage from 'pages/NewsPage';
import newsData from 'data/newsData';
import NewsDetail from 'components/NewsDetail'

function App() {
  return (
    <>
      <Header />
      <Routes>
        <Route index path="/" element={<Home />} />
        <Route element={<Home />} path={'/'} />
        <Route element={<IndustryUsers />} path={'/industryusers'} />
        <Route element={<NewsPage newsData={newsData} />} path={'/news'} />
        <Route element={<NewsDetail />} path={'/news/:id'} />
        <Route element={<AINative />} path={'/ainative'} />
        <Route element={<Team />} path={'/team'} />
        <Route element={<Contactus />} path={'/contactus'} />
        <Route element={<Waiting />} path={'/waiting'} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
