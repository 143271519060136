import { NewsItem } from '../interfaces/NewsItem';

const newsData: NewsItem[] = [
  {
    id: 1,
    newsId: "news1",
    title: "Robotic Factories Supercharge Industrial Digitalization as Electronic Makers Adopt NVIDIA AI and Omniverse",
    newsPageImageUrl: "./img/news/omniverse-industrial-digitalization_mid.png",
    newsDetailImageUrl: "/img/news/omniverse-industrial-digitalization_mid.png",
    mutedVideoUrl: "",
    videoUrl: "",
    summary1: "Robotic Factories Supercharge Industrial Digitalization as Electronic Makers Adopt NVIDIA AI and Omniverse",
    date: "June 3, 2024",
    contents: [
      "NVIDIA Omniverse, Isaac and Metropolis Enable Delta Electronics, Foxconn, Pegatron, Wistron to Digitally Build, Simulate and Operate Factory Digital Twins",
      "COMPUTEX—NVIDIA today announced that major Taiwanese electronics makers are using the company's technology to transform their factories into more autonomous facilities with a new reference workflow. The workflow combines NVIDIA Metropolis vision AI, NVIDIA Omniverse™ physically based rendering and simulation, and NVIDIA Isaac™ AI robot development and deployment.",
      "By using the workflow to build digital twins for real-time simulation of different factory layouts, manufacturers can optimize space, processes and efficiency without costly physical changes.",
      "\"AI for manufacturing is here. Every factory is becoming more and more autonomous due to the transformational impact of generative AI and digital twin technologies,\" said Deepu Talla, vice president of robotics and edge computing at NVIDIA. \"With NVIDIA Omniverse, Metropolis and Isaac, the industrial ecosystem can accelerate its adoption of autonomous technologies, helping advance operational efficiencies and lower costs.\"",
      "NVIDIA Omniverse, Isaac and Metropolis Enable Delta Electronics, Foxconn, Pegatron, Wistron to Digitally Build, Simulate and Operate Factory Digital Twins",
      "Electronics Manufacturers Adopt NVIDIA Technology to Build Robotic Facilities",
      "Delta Electronics, Foxconn, Pegatron and Wistron are using the reference workflow to build, simulate and operate their robotics-enhanced facilities.",
      "In a COMPUTEX keynote demo, NVIDIA founder and CEO Jensen Huang demonstrated how Foxconn, one of the world's largest electronics manufacturers, develops digital twins of its factories on NVIDIA Omniverse, a platform for virtually integrating 3D data from leading industry tools such as Teamcenter from the Siemens Xcelerator platform.",
      "Omniverse helps Foxconn's teams optimize equipment layout for operational flow and AI cameras that will monitor worker safety with NVIDIA Metropolis. Foxconn can then use the factory digital twins as virtual training environments to simulate, test and validate its autonomous mobile robots (AMRs) built on NVIDIA Isaac Perceptor acceleration libraries, as well as its AI robot manipulation arms, which are powered by NVIDIA Isaac Manipulator AI models.",
      "\"AI and robotics are poised to revolutionize manufacturing, enhancing safety on factory floors and driving significant operational efficiencies,\" said Young Liu, CEO and chairman of Foxconn. \"By integrating NVIDIA Omniverse, Metropolis and Isaac into our operations, we can create sophisticated digital twins of our factories to train robots, optimizing workflows with unprecedented precision and reducing costs.\"",
      "Delta Electronics, a manufacturing leader in electronics and IoT-based smart green solutions, is using NVIDIA Isaac Sim™, an extensible robotics simulation platform developed on Omniverse and OpenUSD, an open and extensible ecosystem for 3D worlds, to virtually integrate its demo production lines. It then generates physically accurate, photorealistic synthetic data for training computer vision models for its NVIDIA Metropolis-powered automatic optical inspection and defect detection solutions.",
      "Pegatron, a Taiwan-based manufacturer and service provider, is deploying an NVIDIA Metropolis multi-camera workflow and launching a new suite of services that connects its NVIDIA Omniverse and Metropolis factory digital twin workflow to NVIDIA NeMo™ and NVIDIA NIM™ to help factory operators \"chat\" in real time. The technological advances will help improve worker safety and productivity in Pegatron's massive factory network that spans over 21 million square feet and produces over 15 million assemblies per month.",
      "Wistron, a global leader in electronics manufacturing, has built digital twins of its factories to accelerate the production of NVIDIA DGX™ and NVIDIA HGX™ servers. Now, it is extending its use of Omniverse to develop digital twins of the data centers that are used to test and ensure the quality, performance and energy consumption of newly assembled NVIDIA HGX systems.",
      "Using NVIDIA Omniverse to simulate its facility and workflows first, Wistron brought its factory online in half the typical time — just two and a half months instead of five — and increased worker efficiency by more than 50% through testing and optimizing layouts.",
      "\"The combination of NVIDIA Omniverse and NVIDIA Metropolis allows us to test new layouts virtually to identify new processes and monitor real-time operations using live IoT data from every machine on the production line,\" said Alec Lai, president of global manufacturing at Wistron. \"Digitalizing our factory planning process has reduced end-to-end cycle times by 50%.\"",
      "Ecosystem Expansion to Industrial Applications",
      "Leading Taiwan systems integrator Kenmec is an early implementer of both Omniverse and Metropolis workflows and services for major manufacturers such as Giant Group.",
      "To help developers across the ecosystem, these digital twin workflows are available as a reference architecture series.",
      "Learn more about the newly available updates to NVIDIA Isaac.",
      "Watch Huang's COMPUTEX keynote to get the latest on AI and industrial digitalization.",
      "Source: https://nvidianews.nvidia.com/news/robotic-factories-industrial-digitalization-electronic-makers-ai-omniverse"
    ]
  },
  {
    id: 2,
    newsId: "news2",
    title: "Congratulations! Catherine is an IOAI committee member.",
    newsPageImageUrl: "./img/news/IOAI-logo.png",
    newsDetailImageUrl: "/img/news/IOAI-logo.png",
    mutedVideoUrl: "",
    videoUrl: "",
    summary1: "Congratulations! Catherine is an IOAI committee member.",
    date: "June 2, 2024",
    contents: [
      "We are thrilled to announce that Catherine Li, founder of SiteGenie AI Engineering, was recently selected as the Engineering Liaison for the International Olympiad in Artificial Intelligence (IOAI) in Canada. IOAI is one of the international science olympiads held every summer. It was established in 2023 with the purpose of providing high school students around the world with the opportunity to explore the limitless possibilities of artificial intelligence (AI) and nurturing the next generation of AI pioneers. The first competition will be held in Bulgaria in August 2024.",
      "Catherine Li is a distinguished graduate of the Engineering Science program at the University of Toronto. During her graduate studies in Electrical Engineering, she developed a keen interest in land development, leading her to co-found QH Design Build, a Tarion Registered Corporation renowned for its extensive experience in construction and development projects. Catherine has held key positions in various influential companies and is a staunch advocate for continuing education and lifelong learning, holding numerous certifications.",
      "As an avid supporter of racial and gender equality in engineering, Catherine has made significant contributions to promoting inclusivity and diversity. She has dedicated extensive time to battling racial discrimination and protecting the rights of construction workers of colour.",
      "In early 2023, Catherine founded SiteGenie AI Engineering and Simulator Gravity, focusing on creating AI applications for SME industries. Her extensive experience and commitment to education and equality make her a valuable member of the committee for IOAI Canada. Her leadership and vision are instrumental in fostering an inclusive and equitable environment in the field of AI.",
      "Source: https://www.ioai-canada.org/about"
    ]
  },
  {
    id: 3,
    newsId: "news3",
    title: "IOAI will take place in August 2024 in Bulgaria",
    newsPageImageUrl: "./img/news/IOAI.png",
    newsDetailImageUrl: "/img/news/IOAI.png",
    mutedVideoUrl: "",
    videoUrl: "",
    summary1: "IOAI will take place in August 2024 in Bulgaria",
    date: "June 1, 2024",
    contents: [
      "The International Olympiad in Artificial Intelligence (IOAI) has recently announced its first edition to be held in Bulgaria, a country which has a long tradition of holding the first editions of international olympiads, such as the International Olympiad in Informatics in 1989, the International Linguistics Olympiad in 2003, and the European Junior Olympiad in Informatics in 2017. The participants will be welcomed in the city of Burgas at the Black Sea coast, from August 9th to August 15th, 2024.",
      "IOAI is a prestigious competition that brings together talented students from around the world to showcase their AI skills and to compete against their peers. The event is also an opportunity for the participants to learn from leading experts in the field of AI and to network with like-minded peers.",
      "The competition consists of two rounds: a scientific round and a practical round. In both rounds, the aim of the solutions is not necessarily to reach “the correct answer” as there may not be one. The scientific round is metric-oriented, as solutions are scored based on performance on a pre-defined task-specific metric. The practical round is conclusion-oriented, as participants have to design and perform experiments and to draw conclusions about the capabilities and limitations of AI.",
      "IOAI is a prestigious annual competition designed for high school students who are passionate about the exciting world of artificial intelligence. Our mission is to inspire and challenge young minds to explore the limitless possibilities of AI, fostering the next generation of AI pioneers and problem solvers.",
      "The goals of IOAI include nurturing AI talent, inspiring careers, promoting ethical AI development, and advocating for AI's positive societal impact and diversity. With objectives including fostering creativity and responsible AI usage, it prepares students for future AI careers through learning opportunities and a global network. There are also supplementary events that enrich participants' experiences and provide holistic preparation for their AI careers.",
      "Sources: https://ioai-official.org/2024-bulgaria/, https://ioai-official.org/what-is-ioai/, https://ioai-official.org/charter/"
    ]
  },
  {
    id: 4,
    newsId: "news4",
    title: "Introducing GPT-4o and more tools to ChatGPT free users",
    newsPageImageUrl: "./img/news/chatgpt4o.png",
    newsDetailImageUrl: "/img/news/chatgpt4o.png",
    mutedVideoUrl: "/video/news/chatgpt4o.mp4",
    videoUrl: "",
    summary1: "Introducing GPT-4o and more tools to ChatGPT free users",
    date: "May 13, 2024",
    contents: [
      "Introducing GPT-4o",
      "GPT-4o is our newest flagship model that provides GPT-4-level intelligence but is much faster and improves on its capabilities across text, voice, and vision.",
      "Today, GPT-4o is much better than any existing model at understanding and discussing the images you share. For example, you can now take a picture of a menu in a different language and talk to GPT-4o to translate it, learn about the food's history and significance, and get recommendations. In the future, improvements will allow for more natural, real-time voice conversation and the ability to converse with ChatGPT via real-time video. For example, you could show ChatGPT a live sports game and ask it to explain the rules to you. We plan to launch a new Voice Mode with these new capabilities in an alpha in the coming weeks, with early access for Plus users as we roll out more broadly.",
      "To make advanced AI more accessible and useful worldwide, GPT-4o's language capabilities are improved across quality and speed. ChatGPT also now supports more than 50 languages(opens in a new window) across sign-up and login, user settings, and more.",
      "We are beginning to roll out GPT-4o to ChatGPT Plus and Team users, with availability for Enterprise users coming soon. We are also starting to roll out to ChatGPT Free with usage limits today. Plus users will have a message limit that is up to 5x greater than free users, and Team and Enterprise users will have even higher limits.",
      "Bringing more intelligence and advanced tools for free",
      "Our mission includes making advanced AI tools available to as many people as possible. Every week, more than a hundred million people use ChatGPT. We are starting to roll out more intelligence and advanced tools to ChatGPT Free users over the coming weeks.",
      "When using GPT-4o, ChatGPT Free users will now have access to features such as:",
      "● Experience GPT-4 level intelligence",
      "● Get responses from both the model and the web",
      "● Analyze data and create charts ",
      "● Chat about photos you take",
      "● Upload files for assistance summarizing, writing or analyzing",
      "● Discover and use GPTs and the GPT Store",
      "● Build a more helpful experience with Memory",
      "Source: https://openai.com/index/gpt-4o-and-more-tools-to-chatgpt-free/"
    ]
  },
  {
    id: 5,
    newsId: "news5",
    title: "Collision 2024",
    newsPageImageUrl: "./img/news/collision2024.png",
    newsDetailImageUrl: "/img/news/collision2024.png",
    mutedVideoUrl: "",
    videoUrl: "",
    summary1: "Collision, Toronto | June 17-20, 2024",
    date: "March 3, 2024",
    contents: [
      "Collision is back! North-America's fastest growing technology conference will be held at Enercare Centre, Toronto from June 17th to 20th, 2024. This year's event includes over 800 speakers, 1,400 media, 1,000 investors, 2,000 exhibiting startups, 340 impact startups and 300 partners from over 140 countries, all looking to make meaningful connections. Top themes for 2024 include Artificial Intelligence (AI), the Venture Capital market in 2025 and how to use technology to strengthen democracy.",
      "Attendees of Collision come from various fields such as media, entrepreneurship and technology. Some prominent figures include Connie Loizos, Editor-in-chief of TechCrunch; Geoffrey Hinton, known as the \"Godfather of AI\"; and Jeff Shiner, CEO of 1Password. Additionally, there will be CEOs and founders of companies like Khosla Ventures, Stash, and Google Research. There's also representation from the NBA with Spencer Dinwiddie and the entertainment industry with Nathalie Kelley.",
      "Notable startup companies coming to Collision 2024 include Goloot, a Montreal-based ad network reshaping user interaction with digital ads, catering to global brands like Michael Kors and Microsoft; Climative, a clean tech company utilizing AI and blockchain for digital energy assessments to reduce energy use and carbon emissions in existing buildings; and CentML Inc., an optimization platform for AI deployment, striving to make AI accessible and cost-effective for global innovation. Additionally, SiteGenie AI Engineering is going to make an appearance at Collision as well on June 18th, demonstrating their latest AI-native assistant targeting SME construction industries.",
      "Sources: https://collisionconf.com/featured-attendees, https://collisionconf.com/blog/top-canadian-startups"
    ]
  },
];

export default newsData;
