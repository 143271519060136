import React from 'react';
import { NewsItem as NewsItemType } from '../interfaces/NewsItem';

interface SidebarProps {
    newsItem: NewsItemType;
}

const Sidebar: React.FC<SidebarProps> = ({ newsItem }) => {
    const newsLink = '#' + newsItem.newsId;

    return (
        <aside className="news-sidebar-details">
            <ul>
                <li key={newsItem.id}>
                    <a href={newsLink} ><img src={newsItem.newsPageImageUrl} alt={newsItem.title} ></img></a>
                    <h3>{newsItem.summary1}</h3>
                </li>
            </ul>
            <br />
            <hr />
        </aside>
    );
};

export default Sidebar;
