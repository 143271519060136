import { useLocation } from 'react-router-dom';

function Footer() {
  const location = useLocation();
  const currentPath = location.pathname;
  return <>
    <footer>
      <div className="opacitybg desktop" style={{background: 'rgb(0, 0, 0)'}}></div>
      <div className="opacitybg tablet" style={{background: currentPath === '/waiting' ? 'linear-gradient(180deg, rgba(0, 70, 121, 0.50) 0%, rgba(0, 0, 0, 0.17) 100%)' : 'rgb(0, 0, 0)'}}></div>
      <div className="opacitybg mobile" style={{background: 'rgb(0, 0, 0)'}}></div>
      <div className="z-50 desktop">
        <img src="./img/footer-logo.svg" className="w-[87px] h-[23px]" alt="footer-logo"/>
      </div>
      <div className="z-50 tablet">
          <img src="./img/footer-logo.svg" className="w-[87px] h-[23px]" alt="footer-logo"/>
        </div>
      <div className="z-50 mobile">
        <img src="./img/mobile-footer-logo.svg" className="w-[92px] h-[24px]" alt="footer-logo"/>
      </div>
      <div className="footer-description z-50 mt-[7px]">Contact us via <span>Support@Sitegenie.ai</span></div>
      <div className="footer-copyright z-50 mt-[13px]">© 2024 by  Site Genie</div>
    </footer>
  </>;
}

export default Footer;
