import { NavLink } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { useState } from "react";
import { SITEGENIE_LOGIN_URL } from '../helper';

function Header() {
  const location = useLocation();
  const currentPath = location.pathname;
  const [showMenu, setShowMenu] = useState(false);
  return (
    <>
      <div className="desktop">
        <header className={`h-24 flex items-center${currentPath === '/waiting' ? ' white-header' : ''}`}>
          <div className="w-1/2 flex items-center">
            <img
              src={currentPath === '/waiting' ? "./img/header-logo-white.svg" : "./img/header-logo.svg"}
              className={currentPath === '/waiting' ? "w-[161px] header-logo" : "w-[151px] header-logo"}
              alt="#"
            />
          </div>
          <div className="w-1/2 flex items-center">
            <NavLink to="/" className="header-nav">Home</NavLink>
            <NavLink to="/industryusers" className="header-nav">Industry Users</NavLink>
            <NavLink to="/news" className="header-nav">News</NavLink>
            <NavLink to="/ainative" className="header-nav">AI Native</NavLink>
            <NavLink to="/team" className="header-nav">Team</NavLink>
            <NavLink to="/contactus" className="header-nav">Contact</NavLink>
            <NavLink to="/waiting" className="header-nav">Sign Up</NavLink>
            <NavLink to={SITEGENIE_LOGIN_URL} className="header-nav"><div className="login_button">User Login</div></NavLink>
          </div>
        </header>
      </div>
      <div className="tablet">
        <header className={`h-24 flex items-center${currentPath === '/waiting' ? ' white-header' : ''}`}>
          <div className="w-1/2 flex items-center">
            <img
              src={currentPath === '/waiting' ? "./img/tablet-header-logo-white.svg" : "./img/tablet-header-logo.svg"}
              className={currentPath === '/waiting' ? "w-[161px] header-logo" : "w-[151px] header-logo"}
              alt="#"
            />
          </div>
          <div className="w-1/2 flex items-center">
            <div className="flex flex-col items-center justify-center w-10 h-10 ml-auto mr-[55px] cursor-pointer" onClick={() => setShowMenu(true)}>
              <div className={`${currentPath === '/waiting' ? 'bg-[#FFFFFFAA]' : 'bg-[#000000AA]'} h-2 rounded-md w-[37px]`}></div>
              <div className={`${currentPath === '/waiting' ? 'bg-[#FFFFFFAA]' : 'bg-[#000000AA]'} h-2 rounded-md w-[37px] mt-[6px]`}></div>
              <div className={`${currentPath === '/waiting' ? 'bg-[#FFFFFFAA]' : 'bg-[#000000AA]'} h-2 rounded-md w-[37px] mt-[6px]`}></div>
            </div>
          </div>
        </header>
      </div>
      <div className="mobile">
        <header className={`h-[79px] flex items-center${currentPath === '/waiting' ? ' white-header' : ''}`}>
          <div className="w-1/2 flex items-center">
            <img
              src={currentPath === '/waiting' ? "./img/mobile-header-logo-white.svg" : "./img/mobile-header-logo.svg"}
              className={currentPath === '/waiting' ? "w-[104px] header-logo" : "w-[103px] header-logo"}
              alt="#"
            />
          </div>
          <div className="w-1/2 flex items-center">
            <div>
              <NavLink to={SITEGENIE_LOGIN_URL} className="header-nav mt-[28px]" onClick={() => setShowMenu(false)}><div className="login_button">User Login</div></NavLink>
            </div>
            <div className="flex flex-col items-center justify-center w-10 h-10 ml-auto mr-[27px] cursor-pointer" onClick={() => setShowMenu(true)}>
              <div className={`${currentPath === '/waiting' ? 'bg-[#FFFFFFAA]' : 'bg-[#000000AA]'} h-[6px] rounded-md w-[35px]`}></div>
              <div className={`${currentPath === '/waiting' ? 'bg-[#FFFFFFAA]' : 'bg-[#000000AA]'} h-[6px] rounded-md w-[35px] mt-[4px]`}></div>
              <div className={`${currentPath === '/waiting' ? 'bg-[#FFFFFFAA]' : 'bg-[#000000AA]'} h-[6px] rounded-md w-[35px] mt-[4px]`}></div>
            </div>
          </div>
        </header>
      </div>
      {showMenu && <div className="menulist">
        <div className="close-tap">
          <img src="./img/close.svg" alt="close" className="close-button" onClick={() => setShowMenu(false)} />
        </div>
        <div className="nav-menu">
          <NavLink to="/" className="header-nav" onClick={() => setShowMenu(false)}>Home</NavLink>
          <NavLink to="/industryusers" className="header-nav mt-[28px]" onClick={() => setShowMenu(false)}>Industry Users</NavLink>
          <NavLink to="/news" className="header-nav mt-[28px]" onClick={() => setShowMenu(false)}>News</NavLink>
          <NavLink to="/ainative" className="header-nav mt-[28px]" onClick={() => setShowMenu(false)}>AI Native</NavLink>
          <NavLink to="/team" className="header-nav mt-[28px]" onClick={() => setShowMenu(false)}>Team</NavLink>
          <NavLink to="/contactus" className="header-nav mt-[28px]" onClick={() => setShowMenu(false)}>Contact</NavLink>
          <NavLink to="/waiting" className="header-nav mt-[28px]" onClick={() => setShowMenu(false)}>Sign Up</NavLink>
          <NavLink to={SITEGENIE_LOGIN_URL} className="header-nav mt-[28px]" onClick={() => setShowMenu(false)}><div className="login_button">User Login</div></NavLink>
        </div>

      </div>}
    </>
  );
}

export default Header;
