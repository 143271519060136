import React from 'react';
import CountUp from 'react-countup';

interface Props {
  end: number;
  duration: number;
}

const NumberCounter: React.FC<Props> = ({ end, duration }) => {
  return (
    <div>
      <CountUp end={end} duration={duration} useEasing={false} suffix="+" />
    </div>
  );
};

export default NumberCounter;
