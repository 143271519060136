import React from 'react';
import { NewsItem as NewsItemType } from '../interfaces/NewsItem';
//import { useNavigate } from 'react-router-dom';

interface NewsItemProps {
  newsItem: NewsItemType | undefined;
}

const NewsItem: React.FC<NewsItemProps> = ({ newsItem }) => {
  /*
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/news/${newsItem.id}`);
  };

  return (
    <div className="news-item" onClick={handleClick}>
      <img src={newsItem.newsPageImageUrl} alt={newsItem.title} />
      <div>
        <p>{newsItem.summary1}</p>
        <br />
        <p>{newsItem.date}</p>
      </div>
    </div>
  );*/
  //const { id } = useParams<{ id: string }>();
  //const newsItem = newsData.find(item => item.id.toString() === id);
  const newsContentMap = new Map<number, string>();
  newsItem?.contents.forEach((value, index) => {
    newsContentMap.set(index, value);
  });
  const paragraphs = Array.from(newsContentMap.entries()).map(([key, value]) => (
    <p key={key}>{value}</p>
  ));

  if (!newsItem) {
    return <div>News not found</div>;
  }

  return (
    <div className="news-detail" id={newsItem.newsId}>
      <h1>{newsItem.title}</h1>
      <h2>{newsItem.date}</h2>
      {newsItem.videoUrl === "" && newsItem.mutedVideoUrl === "" && <img src={newsItem.newsDetailImageUrl} alt={newsItem.title} />}
      {newsItem.mutedVideoUrl !== "" && <video id="news-muted-video" autoPlay loop muted playsInline>
                                      <source src={newsItem.mutedVideoUrl} type="video/mp4"/>
                                        Your browser does not support the video tag.
                                   </video>}
      {newsItem.videoUrl !== "" && <video id="news-video" controls>
                                      <source src={newsItem.videoUrl} type="video/mp4"/>
                                        Your browser does not support the video tag.
                                   </video>}
      {paragraphs}
    </div>
  );
};

export default NewsItem;
