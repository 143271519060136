import React from 'react';
import { useParams } from 'react-router-dom';
import newsData from '../data/newsData';

const NewsDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const newsItem = newsData.find(item => item.id.toString() === id);
  const newsContentMap = new Map<number, string>();
  newsItem?.contents.forEach((value, index) => {
    newsContentMap.set(index, value);
  });
  const paragraphs = Array.from(newsContentMap.entries()).map(([key, value]) => (
    <p key={key}>{value}</p>
));

  if (!newsItem) {
    return <div>News not found</div>;
  }

  return (
    <div className="news-detail">
      <h1>{newsItem.title}</h1>
      <h2>{newsItem.date}</h2>
      {newsItem.videoUrl === "" && newsItem.mutedVideoUrl === "" && <img src={newsItem.newsDetailImageUrl} alt={newsItem.title} />}
      {newsItem.mutedVideoUrl !== "" && <video id="news-muted-video" autoPlay loop muted playsInline>
                                      <source src={newsItem.mutedVideoUrl} type="video/mp4"/>
                                        Your browser does not support the video tag.
                                   </video>}
      {newsItem.videoUrl !== "" && <video id="news-video" controls>
                                      <source src={newsItem.videoUrl} type="video/mp4"/>
                                        Your browser does not support the video tag.
                                   </video>}
      {paragraphs}
    </div>
  );
};

export default NewsDetail;
